<template>
  <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
    <h3 class="text-center mb-1">Excellent! Lastly, tell us about your past work experience.</h3>
    <p class="text-center mb-5">
      Upload your resume for in-depth details related to your experience. You'll also be able to create a professional
      resume in-app later!
    </p>
    <b-form-group label-for="workExp" label-class="form-label">
      <template #label> Work Experience <span style="color: red">*</span> </template>
      <v-select
        id="workExp"
        class="form-control v-select-custom"
        :class="
          !$v.profile.work_experience.required && $v.profile.work_experience.$dirty ? 'form-control is-invalid' : ''
        "
        label="text"
        v-model="profile.work_experience"
        :reduce="(exp) => exp.value"
        placeholder="How many years of caregiver experience do you have?"
        :options="workExpOptions"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!profile.work_experience" v-bind="attributes" v-on="events" />
        </template>
      </v-select>
      <b-form-invalid-feedback
        :state="!$v.profile.work_experience.required && $v.profile.work_experience.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Last employer" label-for="curr_employer" label-class="form-label">
      <b-form-input
        id="curr_employer"
        placeholder="Enter last employer's name (if any)."
        v-model="profile.curr_employer"
      />
    </b-form-group>

    <b-form-group label="Resume" label-for="resume" label-class="form-label" class="row-align-items-center">
      <!-- Upload progress while uploading -->
      <b-progress :max="100" animated v-if="isUploading.resume">
        <b-progress-bar :value="uploadPercent.resume">
          <span>
            Uploading (<strong>{{ uploadPercent.resume }} %</strong>)
          </span>
        </b-progress-bar>
      </b-progress>

      <span v-else-if="profile.resume_url">
        <div class="row">
          <div class="col-md-10">
            <a
              href="#"
              @click.prevent="
                downloadFile({
                  fileUrl: profile.resume_url,
                  removeTimestamp: true,
                })
              "
              v-b-popover.hover.top="'Download'"
              ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(profile.resume_url, true) }}
            </a>
          </div>
          <div class="text-right col-md-2">
            <a href="#" class="text-danger" @click.prevent="removeFile('resume', 'resume_url')"
              ><i class="fas fa-times-circle"></i> Remove
            </a>
          </div>
        </div>
      </span>

      <b-media class="align-items-center" vertical-align="center" v-else>
        <b-form-file
          id="resume"
          placeholder="Select Resume File (max size: 10MB, only .pdf, .docx and .doc files allowed)"
          :browse-text="$t('generalMsgs.browse')"
          v-model="selectedUploadFile.resume"
          @input="uploadFile('resume', 'documents', 'resume_url', ['application'])"
          :disabled="isUploading.resume"
          accept=".pdf, .docx, .doc"
        />
      </b-media>
    </b-form-group>
  </b-form>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import axios from 'axios';
import { pick } from 'lodash';
import { parseFileNameFromUrl } from '../../../../common/utils';

export default {
  components: {},

  props: {
    profileData: { type: Object, required: true },
  },

  data() {
    return {
      profile: {
        work_experience: null,
        curr_employer: '',
        resume_url: null,
      },

      isUploading: { resume: false },
      selectedUploadFile: { resume: null },
      uploadPercent: { resume: 0 },
      uploadCancelTokenSource: { resume: null },
      fileSizeExceed: { resume: false },
      invalidFileType: { resume: false },

      workExpOptions: [
        { value: '0-1', text: '0-1 year' },
        { value: '1-2', text: '1-2 years' },
        { value: '3-4', text: '3-4 years' },
        { value: '5-6', text: '5-6 years' },
        { value: '7+', text: '7+ years' },
      ],
    };
  },

  validations() {
    return {
      profile: {
        work_experience: { required },
        curr_employer: {},
        resume_url: {},
      },
    };
  },

  computed: {},

  watch: {
    profileData: {
      handler() {
        this.profile = pick(this.profileData, ['work_experience', 'curr_employer', 'resume_url']);
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('student', ['getMyStudentProfile', 'updateMyStudentProfile', 'getStudentUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('fileDownload', ['downloadFile']),
    parseFileNameFromUrl,

    validateFormData() {
      this.$v.$touch();
      return {
        isValid: !this.$v.$invalid,
        formData: { ...this.profile },
      };
    },

    async uploadFile(fileType, uploadType = 'images', profileUrlField = 'resume_url', allowedTypes = ['image']) {
      this.fileSizeExceed[fileType] = this.invalidFileType[fileType] = false;

      if (this.selectedUploadFile[fileType].size > 10 * 1024 * 1024) {
        this.fileSizeExceed[fileType] = true;
        return;
      } else if (allowedTypes.every((val) => !this.selectedUploadFile[fileType].type.includes(val))) {
        this.invalidFileType[fileType] = true;
        return;
      }

      this.isUploading[fileType] = true;
      this.$emit('isUploading', this.isUploading);
      this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();

      try {
        const urlResp = await this.getStudentUploadPresignedUrl({
          file_name: this.selectedUploadFile[fileType].name,
          content_type: this.selectedUploadFile[fileType].type,
          upload_type: uploadType,
        });
        if (fileType === 'avatar') this.user.avatar_url = urlResp.upload_url.split('?')[0];
        else this.profile[profileUrlField] = urlResp.upload_url.split('?')[0];
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile[fileType],
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent[fileType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token,
          },
        });
      } catch (error) {
        this.selectedUploadFile[fileType] = null;
        if (fileType === 'avatar') this.user.avatar_url = null;
        else this.profile[profileUrlField] = null;
      }

      this.uploadCancelTokenSource[fileType] = null;
      this.uploadPercent[fileType] = 0;

      this.isUploading[fileType] = false;
      this.$emit('isUploading', this.isUploading);
    },

    removeFile(fileType, profileUrlField = 'resume_url') {
      this.selectedUploadFile[fileType] = null;
      this.profile[profileUrlField] = null;
    },
  },

  async mounted() {},
};
</script>
