<template>
  <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
    <h3 class="text-center mb-1">
      Account details confirmed! Tell us more about your educational background
    </h3>

    <b-form-group label="Highest Educational Level" label-for="highest_edu_level" label-class="form-label">
      <v-select
        id="highest_edu_level"
        class="form-control v-select-custom"
        v-model="profile.highest_edu_level"
        placeholder="Select Your Highest Educational Level"
        :options="highestEducationalLevelOptions"
      >
      </v-select>
    </b-form-group>

    <b-form-group
      v-if="profile.highest_edu_level === 'Other (Please Specify)'"
      label-for="highest_edu_level_other"
      label-class="form-label"
    >
      <template #label> Other <span style="color: red">*</span> </template>
      <b-form-input
        id="highest_edu_level_other"
        placeholder="Enter other"
        v-model="profile.highest_edu_level_other"
        :state="
          !$v.profile.highest_edu_level_other.required && $v.profile.highest_edu_level_other.$dirty ? false : null
        "
      />
      <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="Current Educational Status" label-for="current_edu_level" label-class="form-label">
      <v-select
        id="current_edu_level"
        class="form-control v-select-custom"
        v-model="profile.current_edu_level"
        placeholder="Select Your Current Educational Status"
        :options="currentEducationalStatusOptions"
      >
      </v-select>
    </b-form-group>

    <b-form-group
      v-if="profile.current_edu_level === 'Other (Please Specify)'"
      label-for="current_edu_level_other"
      label-class="form-label"
    >
      <template #label> Other <span style="color: red">*</span> </template>
      <b-form-input
        id="current_edu_level_other"
        placeholder="Enter other"
        v-model="profile.current_edu_level_other"
        :state="
          !$v.profile.current_edu_level_other.required && $v.profile.current_edu_level_other.$dirty ? false : null
        "
      />
      <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
    </b-form-group>
  </b-form>
</template>

<script>
import { mapGetters } from 'vuex';
import { pick } from 'lodash';
import { requiredIf } from 'vuelidate/lib/validators';

export default {
  props: {
    profileData: { type: Object, required: true },
    schoolName: { type: String, required: true },
    programName: { type: String, required: true },
  },
  data() {
    return {
      profile: {
        grad_date: null,
        highest_edu_level: null,
        highest_edu_level_other: null,
        current_edu_level: null,
        current_edu_level_other: null,
      },

      highestEducationalLevelOptions: [
        'High School Diploma or GED',
        'Trade/Vocational Certificate',
        'Associate Degree',
        'Bachelor’s Degree',
        'Master’s Degree',
        'Doctoral Degree',
        'Professional Degree (e.g., MD, JD, PharmD)',
        'Completed Certification Program',
        'Other (Please Specify)',
      ],

      currentEducationalStatusOptions: [
        'High School Student',
        'Trade/Vocational School Student',
        'Community College Student',
        'Undergraduate Student',
        'Graduate Student',
        'Professional School Student',
        'Certification Program Student',
        'Postdoctoral Researcher',
        'Continuing Education Student',
        'Not Currently Enrolled in Any Educational Program',
        'Other (Please Specify)',
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUserDashboard']),
  },
  validations() {
    return {
      profile: {
        highest_edu_level_other: {
          required: requiredIf(() => this.profile.highest_edu_level === 'Other (Please Specify)'),
        },
        current_edu_level_other: {
          required: requiredIf(() => this.profile.current_edu_level === 'Other (Please Specify)'),
        },
      },
    };
  },

  watch: {
    profileData: {
      handler() {
        this.profile = pick(this.profileData, [
          'highest_edu_level',
          'highest_edu_level_other',
          'current_edu_level',
          'current_edu_level_other',
        ]);
      },

      deep: true,
    },
  },
  methods: {
    validateFormData() {
      this.$v.$touch();
      return {
        isValid: !this.$v.$invalid,
        formData: { profile: { ...this.profile } },
      };
    },
  },
};
</script>

<style></style>
