<template>
  <div :class="containerClass">
    <div class="back-result-lg font-weight-bold">
      <a href="#" @click.prevent="goBack()" style="text-decoration: underline" class="mr-2">
        <span class="material-icons icon-32pt">navigate_before</span>
      </a>
    </div>

    <page-separator title="" />
    <div :class="containerClass">
      <div
        class="bg-dark employer-profile-bg border-bottom-white overlay overlay--primary-pickled-bluewood mb-3"
        :style="`background-image: url('${get(resource, 'image_url') || DefaultAvatar}'); `"
      >
        <div class="overlay__content page-section employer-profile-cover-ol">
          <div
            class="d-flex flex-column flex-md-row align-items-center text-center text-md-left"
            :class="containerClass"
          >
            <div class="flex mb-32pt mb-md-0">
              <h2 class="text-white mb-0" style="margin-top: 20%" v-text="get(resource, 'title')" />
              <div class="my-2">
                {{ get(resource, 'short_description') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="container">
          <div class="flex" style="max-width: 100%">
            <h3><b-skeleton width="100%"></b-skeleton></h3>
            <div class="mb-0 mt-3" style="text-transform: none">
              <b-skeleton width="100%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
              <b-skeleton width="65%"></b-skeleton>
            </div>
          </div>
        </div>
      </template>
      <div class="container">
        <div class="flex" style="max-width: 100%">
          <div class="mb-0">
            <div style="font-size: 15px" v-html="get(resource, 'description')"></div>
          </div>
        </div>
        <b-btn
          v-if="get(resource, 'external_link')"
          variant="primary"
          :disabled="isLoading"
          class="col-12 col-md-3 mt-2 w-100 btn-normal"
          :href="getClickableLink(get(resource, 'external_link'))"
          target="_blank"
        >
          <md-icon class="icon-16pt mr-1">launch</md-icon>{{ get(resource, 'cta_btn_text') }}
        </b-btn>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Page from '@/components/Page.vue';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import { getClickableLink } from '@/common/utils';
import { DASHBOARD_TYPES } from '@/common/constants';

export default {
  name: 'ResourcePage',

  components: {
    PageSeparator,
  },
  extends: Page,

  data() {
    return {
      title: 'resource Details',
      DefaultAvatar,
      isLoading: false,
      isStatusUpdating: false,
      resource: {},
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser', 'getLoggedInUserDashboard']),
  },
  methods: {
    ...mapActions('school', ['getResourceFromSlug']),
    ...mapActions('organization', ['getOrgResourceFromSlug']),

    get,
    getClickableLink,

    async fetchresource() {
      this.isLoading = true;
      try {
        let res;
        if (this.getLoggedInUserDashboard === DASHBOARD_TYPES.ORGANIZATION) {
          res = await this.getOrgResourceFromSlug({
            slug: this.$route.params.slug,
            org_id: this.getLoggedInUser.linked_entity.id,
          });
        } else {
          res = await this.getResourceFromSlug({ slug: this.$route.params.slug });
        }
        this.resource = res.data;

        this.title = this.resource.title;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
    goBack() {
      this.$router.go(-1);
    },
  },

  async mounted() {
    if (this.$route.params.slug) {
      this.fetchresource();
    }
  },
};
</script>
